import _ from 'lodash'
import { createSelector } from 'reselect'
import { dateFormatBR } from '../../helpers/dateFormat'

export const students = state => _.sortBy(_.get(state, 'student.data', []), 'nome')
export const studentsSimple = state => _.sortBy(_.get(state, 'student.data', []), 'aluno')
export const found = state => _.sortBy(_.get(state, 'student.found', []), 'nome')
export const pagination = state => _.get(state, 'student.pagination', {})
export const getStudentById = (state) => id =>
  createSelector(
    [students],
    students => {
      return _.find(students, { id: parseInt(id) })
    }
  )(state)
export const isLoading = state => _.get(state, 'student.isLoading', false)
export const isLoadingEnrolleds = state => _.get(state, 'student.isLoadingEnrolleds', false)
export const addingStudents = state => _.get(state, 'student.addingStudents', false)
export const error = state => _.get(state, 'student.error', false)
export const errorMessage = state => _.get(state, 'student.errorMessage', '')
export const enrolledCourses = state => _.sortBy(_.get(state, 'student.courses', []), 'nome_turma', 'curso_adicional')

export const formattedScheduledTests = (items) => {
  return (items ?? []).map(item => {
    return {
      ...item,
      dateExamFormatted: dateFormatBR(item.date_exam),
    }
  });
};
