import { all, fork, call, put, takeLatest } from 'redux-saga/effects'
import store from '../store'
import { notification } from 'antd'
import * as api from '../api/courseClass'
import { constants } from '../modules/courseClass'
import { actions as courseClassActions } from '../modules/courseClass'

//
// selectors
import * as courseClassSelectors from '../selectors/courseClass'

function* fetchCourseClasses(action) {
  try {
    const payload = yield call(api.fetchAll, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.COURSE_CLASS_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.COURSE_CLASS_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchSimpleCourseClasses(action) {
  try {
    const payload = yield call(api.fetchAllSimple, action.filters, action)
    yield put({ type: constants.COURSE_CLASS_FETCH_ALL_SIMPLE.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.COURSE_CLASS_FETCH_ALL_SIMPLE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchCourseClassesById(action) {
  try {
    const payload = yield call(api.fetchDetailes, action.id)
    yield put({ type: constants.COURSE_CLASS_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.COURSE_CLASS_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchEnrollmentsById(action) {
  try {
    const payload = yield call(
      api.fetchEnrollmentsById,
      action.idCourseClass,
      action.filters
    );

    yield put({
      type: constants.COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID.SUCCESS,
      payload
    });

    action.next && action.next(undefined, {
      payload
    });
  } catch(e) {
    yield put({
      type: constants.COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID.FAILED
    });
    action.next && action.next({
      e
    });
  }
}

function* addCourseClass(action) {
  try {
    const courseClassData = {
      ...action.formData,
      ativa: 1,
    }

    notification.info({
      key: 'courseClass_add',
      message: 'Adicionando Turma'
    })

    const payload = yield call(api.add, courseClassData)
    yield put({ type: constants.COURSE_CLASS_ADD.SUCCESS, payload })

    notification.close('courseClass_add')
    notification.success({
      message: 'Turma adicionada com sucesso'
    })

    action.next && action.next(payload)
  } catch (e) {
    notification.close('courseClass_add')
    notification.error({
      message: 'Problemas ao adicionar a turma',
      description: e.message || e
    })
    yield put({
      type: constants.COURSE_CLASS_ADD.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}


function* editCourseClass(action) {
  try {
    const courseClassData = {
      ...action.formData,
      active: 1
    }

    //
    // notifications
    notification.info({
      key: 'courseClass_edit',
      message: 'Editando Turma'
    })

    const payload = yield call(api.edit, courseClassData)
    yield put({ type: constants.COURSE_CLASS_EDIT.SUCCESS, payload })

    // notifications
    notification.close('courseClass_edit')
    notification.success({
      message: 'Turma editada com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('courseClass_edit')
    notification.error({
      message: 'Problemas ao editar o turma',
      description: e.message || e
    })
    yield put({
      type: constants.COURSE_CLASS_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* activeToggleCourseClass(action) {
  try {
    const courseClassData = {
      active: action.active
    }

    //
    // notifications
    notification.info({
      key: 'courseClass_edit_active',
      message: action.active ? 'Ativando Turma' : 'Desativando Turma'
    })

    const payload = yield call(api.activeToggle, action.id, courseClassData)
    yield put({ type: constants.COURSE_CLASS_ACTIVE_TOGGLE.SUCCESS, payload })

    // notifications
    notification.close('courseClass_edit_active')
    notification.success({
      message: action.active ? 'Turma ativado com sucesso' : 'Turma desativado com sucesso'
    })

    //
    // get pagination data to refresh the list corretctly
    const state = store.getState()
    const pagination = courseClassSelectors.pagination(state)
    yield put(courseClassActions.fetchCourseClasses(pagination.current_page, pagination.per_page))

    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('courseClass_edit_active')
    notification.error({
      message: action.active ? 'Problemas ao ativar o turma' : 'Problemas ao desativar o turma',
      description: e.message || e
    })
    yield put({
      type: constants.COURSE_CLASS_ACTIVE_TOGGLE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeCourseClass(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'courseClass_remove',
      message: 'Deletando turma'
    })

    const payload = yield call(api.remove, action.id)
    yield put({ type: constants.COURSE_CLASS_REMOVE.SUCCESS, payload })

    // notifications
    notification.close('courseClass_remove')
    notification.success({
      message: 'Turma deletada com sucesso'
    })

    //
    // get pagination data to refresh the list corretctly
    const state = store.getState()
    const pagination = courseClassSelectors.pagination(state)
    yield put(courseClassActions.fetchCourseClasses(pagination.current_page, pagination.per_page))

    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('courseClass_remove')
    notification.error({
      message: 'Problemas ao deletar turma',
      description: e.message || e
    })
    yield put({
      type: constants.COURSE_CLASS_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* enroll(action) {
  try {
    notification.info({
      key: 'courseClass_enroll',
      message: 'Vinculando a turmas'
    })

    const payload = yield call(api.enroll, action.enrollments)
    yield put({ type: constants.COURSE_CLASS_ENROLL.SUCCESS, payload })

    notification.close('courseClass_enroll');
    notification.success({
      message: 'Turmas vinculadas'
    });

    action.next && action.next(payload)
  } catch (e) {
    notification.close('courseClass_enroll')
    notification.error({
      message: 'Problemas ao vincular turma',
      description: e.message || e
    })

    yield put({
      type: constants.COURSE_CLASS_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* unEnroll(action) {
  try {
    const payload = yield call(api.unEnroll, action.formData)
    notification.info({
      key: 'courseClass_unenroll',
      message: 'Desvinculando turma'
    });

    yield put({ type: constants.COURSE_CLASS_ENROLL.SUCCESS, payload });
    notification.close('courseClass_unenroll')
    notification.success({
      message: 'Turma desvinculada'
    });

    action.next && action.next(payload)
  } catch (e) {
    notification.close('courseClass_unenroll')
    notification.error({
      message: 'Problemas ao desvincular a turma',
      description: e.message || e
    })

    yield put({
      type: constants.COURSE_CLASS_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* unEnrollStudent(action) {
  try {
    notification.info({
      key: 'courseClass_unenroll_student',
      message: 'Desvinculando aluno'
    });

    const payload = yield call(api.unEnrollStudent, action.id_turma, action.formData)
    yield put({ type: constants.COURSE_CLASS_UNENROLL_STUDENT.SUCCESS, payload });
    notification.close('courseClass_unenroll_student')
    notification.success({
      message: 'Aluno desvinculado'
    });

    action.next && action.next(payload)
  } catch (e) {
    notification.close('courseClass_unenroll_student')
    notification.error({
      message: 'Problemas ao desvincular o aluno',
      description: e.message || e
    })

    yield put({
      type: constants.COURSE_CLASS_UNENROLL_STUDENT.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeExtraCourse(action) {
  try {
    notification.info({
      key: 'courseClass_remove_extra_course',
      message: 'Removendo curso adicional'
    });

    const payload = yield call(api.removeExtraCourseClass, action.id_turma, action.formData)

    yield put({ type: constants.COURSE_CLASS_REMOVE_EXTRACOURSE.SUCCESS, payload });
    notification.close('courseClass_remove_extra_course')
    notification.success({
      message: 'Curso adicional removido'
    });

    action.next && action.next(payload)
  } catch (e) {
    notification.close('courseClass_remove_extra_course')
    notification.error({
      message: 'Problemas ao remover curso adicional',
      description: e.message || e
    })

    yield put({
      type: constants.COURSE_CLASS_REMOVE_EXTRACOUSE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchAllServices(action) {
  try {
    const payload = yield call(api.fetchAllServices);

    yield put({
      type: constants.COURSE_CLASS_FETCH_ALL_SERVICES.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.COURSE_CLASS_FETCH_ALL_SERVICES.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* fetchAllProducts(action) {
  try {
    const payload = yield call(api.fetchAllProducts);

    yield put({
      type: constants.COURSE_CLASS_FETCH_ALL_PRODUCTS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.COURSE_CLASS_FETCH_ALL_PRODUCTS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

/**
 * Saga
 */
function* watchFetchCourseClasses() {
  yield takeLatest(constants.COURSE_CLASS_FETCH.ACTION, fetchCourseClasses)
}

function* watchFetchSimpleCourseClasses() {
  yield takeLatest(constants.COURSE_CLASS_FETCH_ALL_SIMPLE.ACTION, fetchSimpleCourseClasses)
}

function* watchFetchCourseClassesById() {
  yield takeLatest(constants.COURSE_CLASS_FETCH_BY_ID.ACTION, fetchCourseClassesById)
}

function* watchFetchEnrollmentsById() {
  yield takeLatest(constants.COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID.ACTION, fetchEnrollmentsById)
}

function* watchAddCourseClass() {
  yield takeLatest(constants.COURSE_CLASS_ADD.ACTION, addCourseClass)
}

function* watchEditCourseClass() {
  yield takeLatest(constants.COURSE_CLASS_EDIT.ACTION, editCourseClass)
}

function* watchActiveToggleCourseClass() {
  yield takeLatest(constants.COURSE_CLASS_ACTIVE_TOGGLE.ACTION, activeToggleCourseClass)
}

function* watchRemoveCourseClass() {
  yield takeLatest(constants.COURSE_CLASS_REMOVE.ACTION, removeCourseClass)
}

function* watchEnroll() {
  yield takeLatest(constants.COURSE_CLASS_ENROLL.ACTION, enroll)
}

function* watchUnenroll() {
  yield takeLatest(constants.COURSE_CLASS_UNENROLL.ACTION, unEnroll)
}

function* watchUnenrollStudent() {
  yield takeLatest(constants.COURSE_CLASS_UNENROLL_STUDENT.ACTION, unEnrollStudent)
}

function* watchRemoveExtraCourse() {
  yield takeLatest(constants.COURSE_CLASS_REMOVE_EXTRACOURSE.ACTION, removeExtraCourse)
}

function* watchfetchAllServices() {
  yield takeLatest(constants.COURSE_CLASS_FETCH_ALL_SERVICES.ACTION, fetchAllServices);
}

function* watchfetchAllProducts() {
  yield takeLatest(constants.COURSE_CLASS_FETCH_ALL_PRODUCTS.ACTION, fetchAllProducts);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchAddCourseClass),
    fork(watchEditCourseClass),
    fork(watchRemoveCourseClass),
    fork(watchFetchSimpleCourseClasses),
    fork(watchFetchCourseClasses),
    fork(watchFetchCourseClassesById),
    fork(watchActiveToggleCourseClass),
    fork(watchEnroll),
    fork(watchUnenroll),
    fork(watchUnenrollStudent),
    fork(watchRemoveExtraCourse),
    fork(watchFetchEnrollmentsById),
    fork(watchfetchAllServices),
    fork(watchfetchAllProducts)
  ])
}
